<template>
  <div class="footer">
    <div class="links">
      <button @click="handleClick" v-if="currentRouteName == 'Home'">
        Say Hello
      </button>
      <i
        class="material-icons"
        @click="handleClick"
        v-if="currentRouteName == 'Contact'"
        >arrow_back</i
      >
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentRouteName = computed(() => route.name);

    const handleClick = () => {
      if (currentRouteName.value == 'Home') {
        router.push({ name: 'Contact' });
      } else {
        router.push({ name: 'Home' });
      }
    };

    return { currentRouteName, handleClick };
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  border: 2px solid var(--primary);
  font-weight: 400;
  height: auto;
  width: 150px;
  background-color: var(--background);
  color: var(--text);
  padding: 9px 25px;
  cursor: pointer;
}
button:hover {
  background: var(--primary);

  color: white;
}
i {
  font-size: 2rem;
  cursor: pointer;
  color: var(--primary);
}
i:hover {
  color: var(--primary);
}
</style>
