<template>
  <div class="cta">
    <h3 class="cta-header">Interested in collaborating with me?</h3>
    <p class="cta-text">I'm always interested in opportunities!</p>
    <button @click="handleClick" class="cta-button">
      <img src="@/assets/paper-plane.svg" alt="" />Shoot me a message!
    </button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const handleClick = () => {
      router.push({ name: 'Contact' });
    };
    return { handleClick };
  },
};
</script>

<style scoped>
.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  margin-bottom: 200px;
}

h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-button img {
  width: 40px;
  height: 40px;
  filter: invert(28%) sepia(100%) saturate(747%) hue-rotate(174deg)
    brightness(91%) contrast(92%);
}

button {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  border: 1px solid var(--primary);
  border-width: 2px;
  font-size: 20px;
  font-weight: 500;
  height: 80px;
  width: 400px;
  background-color: var(--background);
  color: var(--primary);
  padding: 9px 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

button:hover {
  background: var(--primary);
  border: 2px solid var(--primary);
  color: white;
}

button:hover img {
  filter: brightness(0) invert(1);
}

/* Media Styles */
@media (max-width: 768px) {
  h3 {
    font-size: 1.25rem;
    text-align: center;
  }

  p {
    font-size: 1rem;
  }

  .cta-button {
    width: 100%;
  }

  button {
    width: 100%;
  }
}
</style>
