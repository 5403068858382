<template>
  <div class="home">
    <div class="hero-container">
      <h1 class="pc-header">Affordable Websites, Big Results</h1>
      <h1 class="mobile-header">
        Affordable Websites,<br />
        Big Results
      </h1>
      <p>I create simple sophisticated Websites, and I love what I do.</p>
      <img src="@/assets/hero.png" alt="" />
    </div>
    <div class="introduction-container">
      <div class="introduction">
        <h2 class="greeting-pc">Hi, I'm Matthias. Nice to meet you.</h2>
        <h2 class="greeting-mobile">
          Hi, I'm Matthias.<br />
          Nice to meet you.
        </h2>
        <p>
          Nothing has excited me more than the idea of creating something new
          and web development has given me the opportunity to do just that. Are
          you looking for a website that is both affordable and effective? I can
          help you with that. I specialize in creating websites that are simple,
          sophisticated, and easy to use and I look forward to working with you!
        </p>
      </div>
    </div>
    <Pitch />
    <Customers />
    <Portfolio :projects="projects" />
    <Collab />
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection';
import Portfolio from '@/components/portfolio/Portfolio.vue';
import Customers from '@/components/portfolio/Customers.vue';
import Pitch from '@/components/Pitch.vue';
import Collab from '@/components/Collab.vue';

export default {
  components: {
    Portfolio,
    Customers,
    Pitch,
    Collab,
  },
  name: 'HomeView',

  setup() {
    const { documents: projects } = getCollection('projects');

    return { projects };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-header {
  display: none;
}

.greeting-mobile {
  display: none;
}

p {
  margin-top: 1rem;
  font-size: 1.5rem;
}
.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 3rem;
  padding-bottom: 0px;
}

img {
  height: 234px;
  width: 234px;
  margin-top: 160px;
  margin-bottom: 160px;
}

.introduction-container {
  width: 100vw;
  height: 300px;
  padding: 3rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  background-color: var(--primary);
  color: white;
}

.introduction-container h2 {
  font-size: 2rem;
}
.introduction-container p {
  font-size: 1.25rem !important;
}
.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  max-width: 800px;
}
.introduction p {
  text-align: left;
}

/* Media Styles */
@media (max-width: 768px) {
  img {
    height: 150px;
    width: 150px;
    margin-bottom: 80px;
  }

  .introduction-container {
    height: 520px;
  }

  .hero-container {
    padding: 2rem 2rem;
    text-align: center;
  }

  p {
    margin-top: 1rem;
    font-size: 1.25rem !important;
  }

  h1 {
    font-size: 2rem !important;
  }
  .introduction p {
    font-size: 1.25rem !important;
    padding: 1rem;
    text-align: center;
  }

  .mobile-header {
    display: block;
  }
  .pc-header {
    display: none;
  }

  .greeting-mobile {
    display: none;
  }
}

/* Smaller Width Media (My Phone) */
@media (max-width: 375px) {
  .mobile-header {
    display: block;
    font-size: 2rem !important;
  }
  .pc-header {
    display: none;
  }

  .greeting-pc {
    display: none;
  }
  .greeting-mobile {
    display: block;
  }

  p {
    margin-top: 1rem;
    font-size: 1.25rem !important;
  }

  img {
    margin-top: 80px;
  }

  .hero-container {
    padding: 1rem 1rem;
    text-align: center;
    margin-top: 40px;
  }

  h1 {
    font-size: 1.5rem !important;
  }

  .introduction-container {
    height: 350px;
  }
  .introduction p {
    font-size: 1rem !important;
    padding: 1rem;
    text-align: center;
  }

  h2 {
    font-size: 24px !important;
  }
}
</style>
