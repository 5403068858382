<template>
  <div class="portfolio-container">
    <h1>Here are my recent projects</h1>
    <p class="instructions">
      You can login with:<br />
      <div class="login-info">
        Login: testuser@test.com<br />
        Password: mattwf</div>
      Or create your own account!
    </p>
    <div class="project-container">
      <div class="project" v-for="project in projects" :key="project.id">
        <div class="title" @click="openInNewTab(project.projectUrl)">
          {{ project.title }}
        </div>
        <div @click="openInNewTab(project.projectUrl)" class="image-container">
          <img class="project-thumbnail" :src="project.thumbnailUrl" />
        </div>
        <div class="framework-container">
          <div class="framework-icon" v-if="project.react == 'yes'">
            <img src="@/assets/icons/react.png" alt="React" />
          </div>
          <div class="framework-icon" v-if="project.vue == 'yes'">
            <img src="@/assets/icons/vue.png" alt="Vue" />
          </div>
          <div class="framework-icon" v-if="project.firebase == 'yes'">
            <img src="@/assets/icons/firebase.png" alt="Firebase" />
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: ['projects'],
  setup() {
    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };
    return { openInNewTab };
  },
};
</script>

<style scoped>
.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-bottom: 4rem;
  background: linear-gradient(to bottom, var(--primary) 65%, white 40%);
  margin-bottom: 0px;
}
h1 {
  font-size: 2rem;
  margin: 2rem;
  margin-top: 100px;
  color: white;
}

.instructions {
  font-size: 1.25rem;
  color: white;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.login-info {
  border: 1px solid white;
  border-radius: 25px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.project {
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
  border-radius: 25px;
  padding: 1rem;
  background-color: transparent;
}

.project .image-container {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 13px 1px var(--accent);
  box-shadow: 0px 0px 13px 1px var(--accent);
}

.project img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin: 0;
}
.title {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}
.framework-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.framework-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}
.framework-icon img {
  margin: 0;
  height: 30px;
  width: 30px;
  cursor: auto;
}

/* Media Styles */

@media (max-width: 1024px) {

  .portfolio-container {
    background: var(--primary);
    margin-bottom: 60px;
  }
  .project-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: var(--primary);
    text-align: center;
    padding-bottom: 120px;
  }

  .instructions {
    margin-top: 60px;
  }

  .project {
    height: fit-content;
    width: 80%;
    margin-bottom: 20px;
    margin-top: 120px;
  }

  .project .image-container {
    width: 100%;
    height: 300px;
    box-shadow: none;
    border: 1px solid white;
    overflow: hidden;
  }

  .framework-icon {
    height: 40px;
    width: 40px;
  }

 
  h1 {
    font-size: 2rem;
    text-align: center;
  }
  .title {
    font-size: 1.5rem;
  }
  img {
    width: 100%;
    height: auto;
  }
  
  .project img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin: 0;
  object-fit: cover;
  }

  .project-thumbnail {
    width: 102% !important;
    height: 102% !important;
  }
}

</style>
