<template>
  <div class="pitch-container">
    <h3 class="pitch-title">What I can offer you!</h3>
    <div class="pitch-columns">
      <div class="pitch-column">
        <img src="@/assets/pitch-icons/personal.png" alt="" />
        <h5>Personal Service</h5>
        <ul>
          <li>Direct communication with me</li>
          <li>No Templates</li>
          <li>Fast responsiveness</li>
          <li>Detailed to your vision</li>
          <li>Full transperancy</li>
        </ul>
      </div>
      <div class="pitch-column">
        <img src="@/assets/pitch-icons/cost.png" alt="" />
        <h5>Cost Saving</h5>
        <ul>
          <li>Lower price to competitors</li>
          <li>One time payment for intial website</li>
          <li>Free changes to intial pages</li>
          <li>Only pay for additional pages</li>
          <li>
            Monthly cost depends on usage<br />
            (Starts out free)
          </li>
          <li>No hidden fees</li>
        </ul>
        <button @click="handleClick" class="pc-button">Get Started!</button>
      </div>
      <div class="pitch-column">
        <img src="@/assets/pitch-icons/features.png" alt="" />
        <h5>Products & Features</h5>
        <ul>
          <li>Landing pages</li>
          <li>Admin pages</li>
          <li>Store management</li>
          <li>Customer management</li>
          <li>Shopping Cart and payments</li>
          <li>Live Chatting</li>
          <li>Messaging Boards</li>
          <li>And more...</li>
        </ul>
        <div class="mobile-cta">
          <button @click="handleClick">Get Started!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();

    const handleClick = () => {
      router.push({ name: 'Contact' });
    };

    return { handleClick };
  },
};
</script>

<style scoped>
.mobile-cta {
  display: none;
}

.pitch-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

h3 {
  margin-top: 200px;
  margin-bottom: 0px;
}

.pitch-columns {
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Aligns columns to the top */
}

.pitch-column {
  width: 40%;
  min-height: 700px;
  padding: 70px 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensures content starts from the top */
  align-items: center; /* Centers text horizontally */
  text-align: center; /* Ensures text is centered */
  border: 1px solid var(--primary);

  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

/* Stop Border Overlapping Second Child */
.pitch-column:nth-child(2) {
  border-left: none;
  border-right: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* First Child */
.pitch-column:first-child {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 0px;
}

/* Last Child */
.pitch-column:last-child {
  border-top-right-radius: 25px;
  border-bottom-left-radius: 0px;
}

img {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

ul,
li {
  all: unset; /* Removes conflicting inherited styles */
}

ul {
  text-align: left;
  margin-top: 2rem;
  font-size: 1.2rem;
  padding-left: 1.5rem;
  display: block;
  list-style-type: disc;
  list-style-position: outside;
}

ul li {
  margin-bottom: 2rem;
  padding-left: 1.5rem; /* Ensures text starts properly after the bullet */
  display: list-item;
}

.pitch-column p {
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: left;
}

button {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  border: 1px solid var(--primary);
  border-width: 2px;
  font-weight: 400;
  height: auto;
  width: 150px;
  background-color: var(--primary);
  color: white;
  padding: 9px 25px;
  cursor: pointer;
  margin-top: 50px;
}

button:hover {
  background: var(--background);
  border: 1px solid var(--primary);
  color: var(--text);
}

/* Mobile */
@media (max-width: 1024px) {
  .pc-button {
    display: none;
  }

  .mobile-cta {
    display: block;
  }

  h3 {
    font-size: 2rem;
    margin-top: 120px;
  }
  .pitch-columns {
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
  }

  .pitch-column {
    width: 80%;
    min-height: 0px;
    max-height: 700px;
    padding: 50px 30px;
  }

  /* First Child */
  .pitch-column:first-child {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0px;
    border-bottom: 0px;
  }

  /* Second Child */
  .pitch-column:nth-child(2) {
    border: 1px solid var(--primary);
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  /* Last Child */
  .pitch-column:last-child {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 25px;
  }

  ul {
    font-size: 1.1rem;
  }

  ul li {
    margin-bottom: 1rem;
  }

  .pitch-column p {
    font-size: 1rem;
  }
}
</style>
