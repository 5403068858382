<template>
  <div class="portfolio-container">
    <h1>Proud to be powering</h1>
    <div class="project-container">
      <div class="project" v-for="project in projects" :key="project.id">
        <div class="title" @click="openInNewTab(project.projectUrl)">
          {{ project.title }}
        </div>
        <div @click="openInNewTab(project.projectUrl)" class="image-container">
          <img :src="project.thumbnailUrl" />
        </div>
      </div>
    </div>
    <button @click="handleClick" class="cta-button">
      <img src="@/assets/icons/shuttle.svg" alt="" />Lets start on your Website!
    </button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const projects = [
      {
        id: 1,
        title: 'Gavi Wine Restaurant',
        thumbnailUrl: require('@/assets/customer/Gavi.png'),
        projectUrl: 'https://www.gaviwinerestaurant.com/',
      },
      {
        id: 2,
        title: 'Anna Banana Bakery',
        thumbnailUrl: require('@/assets/customer/Anna.png'),
        projectUrl: 'https://www.annabananabakery.ca/',
      },
    ];

    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };

    const handleClick = () => {
      router.push({ name: 'Contact' });
    };

    return { openInNewTab, projects, handleClick };
  },
};
</script>

<style scoped>
.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-bottom: 4rem;
  margin-top: 200px;
  margin-bottom: 100px;
}
h1 {
  font-size: 2.5rem;
  margin: 2rem 0;
  margin-bottom: 60px;
}
.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
}
.project {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
  border-radius: 25px;
  padding: 1rem;
  background-color: var(--background);
}

.project .image-container {
  width: 350px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  border: 1px solid var(--primary);
  border-radius: 25px;
  /* -webkit-box-shadow: 0px 0px 13px 1px var(--accent);
  box-shadow: 0px 0px 13px 1px var(--accent); */
}

.project img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin: 0;
}
.title {
  font-size: 1.5rem;
  cursor: pointer;
}

.cta-button img {
  width: 40px;
  height: 40px;
  filter: invert(28%) sepia(100%) saturate(747%) hue-rotate(174deg)
    brightness(91%) contrast(92%);
}

button {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  border: 1px solid var(--primary);
  border-width: 2px;
  font-size: 20px;
  font-weight: 500;
  height: 80px;
  width: 400px;
  background-color: var(--background);
  color: var(--primary);
  padding: 9px 25px;
  cursor: pointer;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

button:hover {
  background: var(--primary);
  border: 2px solid var(--primary);
  color: white;
}

button:hover img {
  filter: brightness(0) invert(1);
}

/* Media Styles */
@media (max-width: 768px) {
  .portfolio-container {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .project-container {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  .project {
    width: 80%;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 1.5rem;
  }
  .title {
    font-size: 1.25rem;
  }
  img {
    width: 100%;
    height: auto;
  }

  button {
    width: 300px;
    font-size: 16px;
    height: 80px;
    gap: 0px;
  }

  .cta-button img {
    width: 30px;
    height: 30px;
  }

  .cta-button {
    margin-top: 30px;
  }

  .project .image-container {
    width: 100%;
    height: 200px;
  }
}
</style>
